import { css } from 'styled-components'
import { TYPOGRAPHY } from '@junglescout/styles-library'

function isNumberCell(props) {
  return props.type === 'number'
}

export const cellStyle = css`
  ${TYPOGRAPHY.bodySm};
  justify-content: ${props =>
    isNumberCell(props) ? 'flex-end' : 'flex-start'};
  align-items: flex-start;
  border: ${props => props.border || 'none'};
  border-bottom: 1px solid ${props => props.theme.colors.grey100};
  margin: 0;
  padding: 8px 5px 8px 10px;
  min-height: 53px;
  background-color: inherit;
`

export const tdCellStyle = css`
  ${cellStyle}
  ${props => {
    if (isNumberCell(props) && props.children.props.column.sortable) {
      return 'padding-right: 30px;'
    }
    return ''
  }}
`

export const stickyColProps = css`
  position: sticky;
  z-index: 6;
`

export const colLeftShadow = css`
  box-shadow: 7px 0 10px 0 rgba(101, 101, 101, 0.1);
`

export const tableActionsWrapperStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 55px;
  padding: 8px 14px;
  border: solid 1px ${props => props.theme.colors.grey100};
  border-radius: 8px 8px 0 0;
  border-right: none;
  background-color: ${props => props.theme.colors.white};
`

export const sizes = {
  small: 67,
  medium: 117,
  large: 157,
  'x-large': 207,
  'xx-large': 357
}
