import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ClickableIcon, Icon, Text } from '@junglescout/edna'

import { setBannerShowAfterTime } from 'helpers/banners'

const BANNER_TYPES = {
  info: {
    primaryColor: 'blue100',
    textColor: 'blue600',
    iconColor: 'blue600'
  },
  warning: {
    primaryColor: 'yellow050',
    textColor: 'grey900',
    iconColor: 'yellow700'
  },
  error: {
    primaryColor: 'red050',
    textColor: 'grey900',
    iconColor: 'red500'
  }
}

const calcBackgroundColor = ({ type, theme: { colors } }) => {
  const bannerType = BANNER_TYPES[type]

  if (bannerType) {
    return colors[bannerType.primaryColor]
  }

  return colors.grey900
}

const BannerWrapper = styled.section`
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${props => calcBackgroundColor(props)};
  min-height: 40px;
`
const Message = styled(Text)`
  display: flex;
  align-items: center;
  flex-flow: wrap;
  gap: 4px 8px;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  gap: 2px 8px;
  width: 100%;
`

const ActionRequired = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  gap: 8px;
`
const TopBanner = ({
  id,
  type,
  message,
  dismissible,
  onDismiss,
  showAfter,
  title
}) => {
  const { t } = useTranslation(['generic'])
  const dispatch = useDispatch()

  const onClick = () => {
    onDismiss({ id })

    dispatch({
      type: 'REMOVE_BANNER',
      payload: id
    })

    setBannerShowAfterTime({ id, showAfter })
  }

  const displayTitle =
    title || `${t('generic:ActionRequired', 'Action Required')}:`

  return (
    <BannerWrapper type={type} dismissible={dismissible} role="status">
      <InnerWrapper>
        <ActionRequired>
          <Icon name="ALERT_CIRCLE" color={BANNER_TYPES[type]?.iconColor} />
          <Text
            variant="bodyMd"
            element="span"
            fontWeight={600}
            color={BANNER_TYPES[type]?.textColor}>
            {displayTitle}
          </Text>
        </ActionRequired>
        <Message
          element="div"
          variant="bodyMd"
          color={BANNER_TYPES[type]?.textColor}>
          {message}
        </Message>
      </InnerWrapper>

      {dismissible && (
        <ClickableIcon
          name="X_CLOSE"
          width="20px"
          height="20px"
          padding="2px"
          onClick={onClick}
          ariaLabel={`Close banner ${id}`}
        />
      )}
    </BannerWrapper>
  )
}

TopBanner.defaultProps = {
  message: undefined,
  dismissible: false,
  onDismiss: () => {},
  showAfter: -1, // seconds
  title: undefined
}

TopBanner.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  dismissible: PropTypes.bool,
  onDismiss: PropTypes.func,
  showAfter: PropTypes.number, // seconds
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export { TopBanner }
